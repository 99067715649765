
export default class Mouse
{
	constructor() {

		this.positions =
		{
			clientX : window.innerWidth  /2,
			clientY : window.innerHeight /2,
			pageX   : window.innerWidth  /2,
			pageY   : window.innerHeight /2
		};

		this.initEvents();

	}

	initEvents() {

		this.boundEventMousemove = (event) => this.mousemove(event);

		window.addEventListener('mousemove', this.boundEventMousemove);

	}

	mousemove (event) {

		this.getMousePosition(event);

	}

	getMousePosition (event) {

		let clientX = 0;
		let clientY = 0;

		let pageX = 0;
		let pageY = 0;

		if (!event) event = window.event;

		if (event.clientX || event.clientY)
		{
			clientX = event.clientX;
			clientY = event.clientY;
		}

		if (event.pageX || event.pageY)
		{
			pageX = event.pageX;
			pageY = event.pageY;
		}
		 else
		{
			pageX = clientX + document.body.scrollLeft + document.documentElement.scrollLeft;
			pageY = clientY + document.body.scrollTop  + document.documentElement.scrollTop;
		}

		this.positions = {
			clientX : clientX,
			clientY : clientY,
			pageX : pageX,
			pageY : pageY
		};

	}

	getPositions () {

		return this.positions;

	}

	destroy () {

		window.removeEventListener('mousemove', this.boundEventMousemove);

	}
}