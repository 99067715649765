
export default class Embed {

	constructor() {

		this.DOM = {};

		this.embeds = []

	}

	/*
	** stop
	*/

	stopAll() {

		this.stopAllExcept(-1);

	}

	stopAllExcept (exceptIndex) {

		for (let i = 0; i < this.count; ++i)
		{
			if (i !== exceptIndex)
			{
				const el = this.embeds[i];

				el.iframe.src = el.src;
			}
		}

	}

	/*
	** load
	*/

	load() {

		this.DOM.embed = document.querySelectorAll('.embed');

		this.count = this.DOM.embed.length;

		for (let i = 0; i < this.count; ++i)
		{
			const embed = this.DOM.embed[i];

			const iframe = embed.querySelector('iframe');

			const listen = parseInt(embed.getAttribute('data-embed-listen'));

			let src = '';

			if ( iframe !== null )
			{
				// src
				src = iframe.src;

				// fluid
				const width = iframe.getAttribute('width');
				const height = iframe.getAttribute('height');
				const ratio = ( height / width ) * 100;

				iframe.style.position = 'absolute';
				iframe.style.top = '0';
				iframe.style.left = '0';
				iframe.style.width = '100%';
				iframe.style.height = '100%';

				const wrap = document.createElement('div');
				wrap.className = 'fluid-vids';
				wrap.style.width = '100%';
				wrap.style.position = 'relative';
				wrap.style.paddingTop = ratio + '%';

				const iframeParent = iframe.parentNode;
				iframeParent.insertBefore( wrap, iframe );

				wrap.appendChild( iframe );

				// event
				if ( listen )
				{
					embed.addEventListener('mouseenter', event =>
					{
						this.stopAllExcept(i);

					});
				}

			}

			// push
			this.embeds[i] = {
				embed : embed,
				iframe : iframe,
				src : src,
				listen: listen
			};
		}

	}


	/*
	** unload
	*/

	unload() {

		//

	}

}