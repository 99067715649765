import Mouse from './Mouse.js';

export default class Cursor extends Mouse
{
	constructor(el) {

		super();

		this.DOM = {el: el};

		this.bounds = this.DOM.el.getBoundingClientRect();

		this.init();

		this.render();
	}

	init() {

		const mouse = this.getPositions();

		this.lm =
		{
			x: mouse.clientX - this.bounds.width  /2,
			y: mouse.clientY - this.bounds.height /2
		};

		this.lastScale = 1;

		this.lastOpacity = 1;
	}

	render() {

		const mouse = this.getPositions();

		const factor = 0.1;

		this.lm.x = this.lerp(this.lm.x, mouse.clientX - this.bounds.width  /2, factor);
		this.lm.y = this.lerp(this.lm.y, mouse.clientY - this.bounds.height /2, factor);

		this.lastScale   = this.lerp(this.lastScale, 1, 0.15);
		this.lastOpacity = this.lerp(this.lastOpacity, 1, 0.1);

		this.DOM.el.style.transform = `translateX(${(this.lm.x)}px) translateY(${this.lm.y}px) scale(${this.lastScale})`;

		this.DOM.el.style.opacity = this.lastOpacity;

		this.request = window.requestAnimationFrame( () => this.render() );
	}

	click() {

		this.lastScale = 0.5;

		this.lastOpacity = 0;
	}

	lerp (a, b, n) {

		return (1 - n) * a + n * b;
	}

	destroy () {

		window.cancelAnimationFrame(this.request);

		super.destroy();
	}
}