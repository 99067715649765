
export default class Path {

	constructor (path = '') {

		this.path = path;

	}

	/*
	** setBaseUrl
	*/

	static setBaseUrl (value) {

		this.path = value;

	}

	/*
	** getBaseUrl
	*/

	static getBaseUrl () {

		return this.path;

	}

}