import Console from './Console.js';
import Util    from './Util.js';
import Loading from './Loading.js';

export default class Preloading {

	/*
	** show
	*/

	static show() {

		Console.log('Preloading::Show');

		// loading
		Loading.show();

		// class
		document.body.classList.add('body--preloader');

	}

	/*
	** hide
	*/

	static hide (callback) {

		Console.log('Preloading::hide');

		const preloaderEl = document.querySelector('.preloader');

		preloaderEl.classList.add('show');

		setTimeout( () =>
		{
			// loading
			Loading.hide();

			// hide
			preloaderEl.classList.add('hide');

			setTimeout( () =>
			{
				// hidden
				preloaderEl.classList.add('hidden');

				// class
				document.body.classList.remove('body--preloader');

				// callback
				if (callback && typeof(callback) === 'function')
				{
					callback();
				}

			}, 600);
 
		}, 600);
	}

}