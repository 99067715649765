import PubSub from 'pubsub-js';
import Util from '../common/Util.js';
import Page from '../common/Page.js';

import Embed from '../class/Embed.js';
import Observable from '../class/Observable.js';

import VideoPlayOnScreen from './VideoPlayOnScreen.js';
import MyArticles from './MyArticles.js';
import MySlideshows from './MySlideshows.js';
import MyMarquee from './MyMarquee.js';
import MyTooltip from './MyTooltip.js';


export default class MyPage extends Page {

	constructor() {

		super();

		this.DOM.root = document.querySelector(':root');

		this.DOM.section = document.querySelector('.section');

		this.name = this.DOM.section.getAttribute('data-controller');

	}

	/*
	** init
	*/

	init() {

		// super
		super.init();

		// color
		this.color = this.DOM.section.getAttribute('data-page-color');
		this.bgcolor = this.DOM.section.getAttribute('data-page-bgcolor');

		if ( this.color && this.bgcolor )
		{
			this.DOM.root.style.setProperty('--color', this.color);
			this.DOM.root.style.setProperty('--bgcolor', this.bgcolor);
		}
		 else
		{
			this.DOM.root.style.setProperty('--color', '#000');
			this.DOM.root.style.setProperty('--bgcolor', '#fff');
		}

		// videoPlayOnScreen
		this.videoPlayOnScreen = new VideoPlayOnScreen();
		this.videoPlayOnScreen.load();

		// embed
		this.embed = new Embed();
		this.embed.load();

		// pubsub
		this.pubSubPageChange = PubSub.subscribe('page.onchange', (msg, data) =>
		{
			this.embed.stopAll();
		});

		// observable
		this.observable = new Observable();
		this.observable.load();

		// articles
		this.articles = new MyArticles();
		this.articles.load();

		// slideshows
		this.slideshows = new MySlideshows();
		this.slideshows.load();

		// marquee
		this.marquee = new MyMarquee('.section .marquee', 0.2);
		this.marquee.load();

		// tooltip
		this.tooltip = new MyTooltip();
		this.tooltip.load();

	}

	/*
	** scrollTo
	*/

	scrollTo (value) {

		Util.setScrollTop(value);

	}

	/*
	** enter
	*/

	enter() {

		// super
		super.enter();

		// class
		setTimeout( () =>
		{
			this.DOM.body.classList.add('body--page-start');

		}, 600);

		this.DOM.body.classList.add('body--page-enter');

	}

	/*
	** unload
	*/

	unload() {

		// videoPlayOnScreen
		if (this.videoPlayOnScreen !== undefined)
		{
			this.videoPlayOnScreen.unload();
			delete this.videoPlayOnScreen;
		}

		// embed
		if (this.embed !== undefined)
		{
			this.embed.unload();
			delete this.embed;
		}

		// pubsub
		if (this.pubSubPageChange !== undefined)
		{
			PubSub.unsubscribe(this.pubSubPageChange);
			delete this.pubSubPageChange;
		}

		// swiper
		if (this.swiper !== undefined)
		{
			this.swiper.unload();
			delete this.swiper;
		}

		// observable
		if (this.observable !== undefined)
		{
			this.observable.unload();
			delete this.observable;
		}

		// articles
		if (this.articles !== undefined)
		{
			this.articles.unload();
			delete this.articles;
		}

		// slideshows
		if (this.slideshows !== undefined)
		{
			this.slideshows.unload();
			delete this.slideshows;
		}

		// marquee
		if (this.marquee !== undefined)
		{
			this.marquee.unload();
			delete this.marquee;
		}

		// tooltip
		if (this.tooltip !== undefined)
		{
			this.tooltip.unload();
			delete this.tooltip;
		}

		// class
		this.DOM.body.classList.remove('body--page-enter');
		this.DOM.body.classList.remove('body--page-start');

		// super
		super.unload();

	}

}