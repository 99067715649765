
export default class Observable {

	constructor() {

		this.DOM = {};

		this.DOM.body = document.body;

	}

	/*
	** observe
	*/

	observe() {

		const config =
		{
			root: null,
			rootMargin: '0px',
			threshold: 0
		}

		const callback = (entries) =>
		{
			entries.forEach( (entry) =>
			{
				if ( entry.isIntersecting )
				{
					entry.target.classList.add('observed');
				}
				 else
				{
					// entry.target.classList.remove('observed');
				}
			});
		}

		this.observer = new IntersectionObserver(callback, config);

		this.DOM.items.forEach( element =>
		{
			this.observer.observe(element);
		});

	}

	/*
	** unobserve
	*/

	unobserve() {

		if ( this.DOM.items !== undefined )
		{
			this.DOM.items.forEach( element =>
			{
				this.observer.unobserve(element);
			});
		}

	}

	/*
	** load
	*/

	load() {

		this.DOM.items = document.querySelectorAll('*[observable]');

		if ( !this.DOM.items.length )
		{
			return false;
		}

		// observe
		this.observe();

	}

	/*
	** unload
	*/

	unload() {

		// unobserve
		this.unobserve();

	}

}