import Cursor from '../class/Cursor.js';
import Lazyload from '../common/Lazyload.js';


export default class MyCursor {

	constructor() {

		this.DOM = {};

	}

	/*
	** load
	*/

	load() {

		this.DOM.cursor = document.querySelector('.cursor');

		this.DOM.cursorContent = this.DOM.cursor.querySelector('.cursor__content');

		this.cursor = new Cursor( this.DOM.cursor );

	}

	/*
	** show
	*/

	show ( el ) {

		this.DOM.cursorContent.appendChild( el );

		this.DOM.cursor.classList.add('showed');

		Lazyload.update();

	}

	/*
	** hide
	*/

	hide() {

		let child = this.DOM.cursorContent.lastElementChild;

		while (child)
		{
			this.DOM.cursorContent.removeChild(child);

			child = this.DOM.cursorContent.lastElementChild;
		}

		this.DOM.cursor.classList.remove('showed');

	}

	/*
	** unload
	*/

	unload() {

		// hide
		this.hide();

		// cursor
		if (this.cursor !== undefined)
		{
			this.cursor.destroy();
			this.cursor = undefined;
		}

	}

}