import Swiper from 'swiper';
import { Navigation, Pagination } from 'swiper/modules';

export default class MyArticles {

	constructor() {

		this.DOM = {};

	}

	/*
	** load
	*/

	load() {

		this.DOM.items = document.querySelectorAll('.articles');

		this.DOM.items.forEach( item =>
		{
			const id = '#' + item.id;

			item.swiper = new Swiper(id,
			{
				modules: [Navigation, Pagination],
				slidesPerView: 'auto',
				centeredSlides: false,
				spaceBetween: 0,
				grabCursor: true,
				freeMode: true,
				pagination:
				{
					el: id + ' .swiper-pagination',
					clickable: true,
				},
			});
		});

	}

	/*
	** unload
	*/

	unload() {

		this.DOM.items.forEach( item =>
		{
			if (item.swiper !== undefined)
			{
				item.swiper.destroy();
				delete item.swiper;
			}
		});

	}

}