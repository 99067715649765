import PubSub    from 'pubsub-js';
import Console   from './common/Console.js';
import PageEvent from './common/PageEvent.js';
import Util      from './common/Util.js';
import Loading   from './common/Loading.js';

import Menu from './module/Menu.js';
import MyMarquee from './module/MyMarquee.js';
import MyFooter  from './module/MyFooter.js';

export default class Navigation extends PageEvent {

	constructor() {

		super();

		this.DOM = {};

		this.DOM.body = document.body;

		this.DOM.header = document.querySelector('.header');

		this.lastScroll = 0;

	}

	/*
	** setActive
	*/

	static setActive (controller, page_id, page_name) {

		Console.log('Navigation::setActive('+ controller +' ,' + page_id + ',' + page_name + ')');

		this.selectSection(controller);

		this.selectPage(page_id, page_name);

	}

	static selectSection (controller) {

		// sections
		const sectionsEl = document.querySelectorAll('.sections__item');

		for (let i = 0; i < sectionsEl.length; ++i)
		{
			sectionsEl[i].classList.remove('selected');

			const anchor = sectionsEl[i].querySelector('.sections__anchor').classList.remove('selected');
		}

		// section
		const sectionEl = document.querySelector('.sections__item[data-controller="'+ controller +'"]');

		if (sectionEl !== null)
		{
			sectionEl.classList.add('selected');

			sectionEl.querySelector('.sections__anchor').classList.add('selected');
		}

	}

	static selectPage (page_id, page_name) {

		// pages
		const pagesEl = document.querySelectorAll('.pages__anchor');

		for (let i = 0; i < pagesEl.length; ++i)
		{
			pagesEl[i].classList.remove('selected');
		}

		// page
		const pagesSelectedEl = document.querySelectorAll('.pages__anchor[data-id="'+ page_id +'"]');

		for (let i = 0; i < pagesSelectedEl.length; ++i)
		{
			pagesSelectedEl[i].classList.add('selected');
		}

		// selected
		/*
		const name = document.querySelector('.entity__selected span');

		if ( page_name !== null )
		{
			name.innerHTML = page_name;
		}
		 else
		{
			name.innerHTML = '';
		}
		*/

	}


	/*
	** init
	*/

	init() {

		// burger
		this.burger();

		// flap
		this.flap();

		// menu
		this.menu = new Menu();
		this.menu.load();

		// marquee
		this.marquee = new MyMarquee('.footer .marquee', 0.2);
		this.marquee.load();

		// footer
		this.footer = new MyFooter();
		this.footer.load();

	}

	/*
	** burger
	*/

	burger() {

		const burger = this.DOM.header.querySelector('.header__burger');

		burger.addEventListener('click', event =>
		{
			event.preventDefault();
			event.stopPropagation();

			if ( document.body.classList.contains('body--header-opened') )
			{
				this.close();
			}
			 else
			{
				this.open();
			}

		}, false);

	}

	open() {

		this.DOM.body.classList.add('body--header-opened');

	}

	close() {

		this.DOM.body.classList.remove('body--header-opened');

	}

	/*
	** flap
	*/

	flap() {

		const flap = this.DOM.header.querySelector('.header__flap');

		flap.addEventListener('mouseenter', event =>
		{
			this.DOM.body.classList.add('body--header-hover');

		}, false);

		flap.addEventListener('mouseleave', event =>
		{
			this.DOM.body.classList.remove('body--header-hover');

		}, false);

	}

	/*
	** resize
	*/

	resize (event) {

		//

	}

	/*
	** scroll
	*/

	scroll (event) {

		super.scroll(event);

		// scrolled
		this.isScrolled();

		// scrollDown
		// this.isScrollDown();

	}

	isScrolled() {

		const scrollTop = Util.getScrollTop();

		if ( scrollTop > 5 )
		{
			this.DOM.body.classList.add('body--scrolled');
		}
		 else
		{
			this.DOM.body.classList.remove('body--scrolled');
		}

		if ( scrollTop > (Util.windowHeight()) )
		{
			this.DOM.body.classList.add('body--xscrolled');
		}
		else
		{
			this.DOM.body.classList.remove('body--xscrolled');
		}

	}
/*
	isScrollDown() {

		const currentScroll = window.pageYOffset;

		if (currentScroll > this.lastScroll)
		{
			if ( !this.DOM.body.classList.contains('scroll-down') )
			{
				this.DOM.body.classList.add('scroll-down');
			}
		}
		 else if (currentScroll < this.lastScroll)
		{
			if ( this.DOM.body.classList.contains('scroll-down') )
			{
				this.DOM.body.classList.remove('scroll-down');
			}
		}

		this.lastScroll = currentScroll;

	}
*/
	/*
	** start
	*/

	start() {

		Console.log('Navigation::start');

		// load
		this.load();

		// init
		this.init();

		// resize
		this.resize();

	}

}