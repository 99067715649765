import MyCursor from './MyCursor.js';


export default class MyTooltip {

	constructor() {

		this.DOM = {};

	}

	/*
	** init
	*/

	init() {

		this.DOM.items = document.querySelectorAll('.tooltip');

		this.count = this.DOM.items.length;

		if ( !this.count )
		{
			return false;
		}

		// cursor
		this.cursor = new MyCursor();
		this.cursor.load();

		// items
		this.DOM.items.forEach( item =>
		{
			const anchor = item.querySelector('[tooltip-anchor]');
			const thumbnail = item.querySelector('.tooltip__thumbnail');
			const x = item.getAttribute('data-translateX');
			const y = item.getAttribute('data-translateY');

			if ( (anchor !== null) && (thumbnail !== null) )
			{
				anchor.addEventListener('mouseenter', event =>
				{
					const clone = thumbnail.cloneNode(true);

					clone.style.transform = 'translate('+ x +','+ y +')';

					this.cursor.show( clone );
				});

				anchor.addEventListener('mouseleave', event =>
				{
					this.cursor.hide();
				});

				anchor.addEventListener('click', event =>
				{
					this.cursor.hide();
				});
			}
		});

	}

	/*
	** load
	*/

	load() {

		// init
		this.init();

	}

	/*
	** unload
	*/

	unload() {

		// cursor
		if (this.cursor !== undefined)
		{
			this.cursor.unload();
			this.cursor = undefined;
		}

	}

}