
export default class MyMarquee {

	constructor(selector, speed) {

		this.DOM = {};

		this.selector = selector;

		this.speed = speed;

	}

	/*
	** load
	*/

	load() {

		this.DOM.items = document.querySelectorAll(this.selector);

		this.DOM.items.forEach( item =>
		{
			const clone = item.innerHTML;

			const firstElement = item.children[0];

			let i = 0;

			item.insertAdjacentHTML('beforeend', clone);

			item.insertAdjacentHTML('beforeend', clone);

			item.interval = setInterval( () =>
			{
				firstElement.style.marginLeft = `-${i}px`;

				if (i > firstElement.clientWidth)
				{
					i = 0;
				}

				i = i + this.speed;

			}, 0);
		});

	}

	/*
	** unload
	*/

	unload() {

		this.DOM.items.forEach( item =>
		{
			if (item.interval !== undefined)
			{
				clearInterval(item.interval);
				delete item.interval;
			}
		});

	}

}