import MyPage from '../module/MyPage.js';
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger.js";

export default class Home extends MyPage {

	constructor() {

		super();

	}

	/*
	** init
	*/

	init() {

		// super
		super.init();

		// poster
		gsap.registerPlugin(ScrollTrigger);

		this.timeline = gsap.timeline(
		{
			scrollTrigger:
			{
				trigger: '.page__content .poster',
				scrub: true,
				pin: false,
				start: 'top top',
				end: '+=500'
			}
		});

		this.timeline
		.add('a')
		.fromTo(
			'.page__content .poster',
			{
				clipPath: 'inset(0em 0em 0em 0em)',
			},
			{
				clipPath: 'inset(0em 3em 6em 3em)',
			},
			'a'
		);

	}

	/*
	** unload
	*/

	unload() {

		// super
		super.unload();

	}
}