
export default class MyProductions {

	constructor() {

		this.DOM = {};

	}

	/*
	** init
	*/

	init () {

		const now = new Date();

		this.DOM.items.forEach( item =>
		{
			const d = item.getAttribute('data-date-end');
			const date = new Date(d);

			if (date < now)
			{
				item.classList.add('past');

				// this.DOM.container.appendChild(item);
			}

		});

	}

	/*
	** load
	*/

	load() {

		// productions
		this.DOM.productions = document.querySelector('.productions');

		if (this.DOM.productions === null)
		{
			return false;
		}

		// container
		this.DOM.container = document.querySelector('.productions__container');

		// items
		this.DOM.items = this.DOM.productions.querySelectorAll('.productions__item');

		this.count = this.DOM.items.length;

		if ( !this.count )
		{
			return false;
		}

		// init
		this.init();

	}

	/*
	** unload
	*/

	unload() {

		//

	}

}