
export default class Menu {

	constructor() {

		this.DOM = {};

		this.DOM.body = document.body;

	}

	/*
	** init
	*/

	init() {

		this.DOM.header = document.querySelector('.header');

		this.DOM.sections = this.DOM.header.querySelectorAll('.sections__item.is-pages');

		this.DOM.sections.forEach( item =>
		{
			const anchor = item.querySelector('.sections__name .anchor');

			anchor.addEventListener('click', event =>
			{
				event.preventDefault();
				event.stopPropagation();

				if ( ! item.classList.contains('selected') )
				{
					this.closePages();

					item.classList.add('selected');

				}
				 else
				{
					this.closePages();
				}

				return false;

			}, false);
		});

	}

	closePages() {

		this.DOM.sections.forEach( item =>
		{
			item.classList.remove('selected');
		});

	}

	/*
	** load
	*/

	load() {

		this.init();

	}

	/*
	** unload
	*/

	unload() {

		//

	}

}