import PageEvent from '../common/PageEvent.js';
import Util from '../common/Util.js';


export default class VideoPlayOnScreen extends PageEvent {

	constructor() {

		super();

		this.DOM = {};

		this.count = 0;

		// bounds
		this.boundEventResize = Util.debounce(event => this.resize(event), 500);
		this.boundEventScroll = Util.debounce(scroll => this.scroll(event), 500);

	}

	/*
	** resize
	*/

	resize (event) {

		this.playVideosInViewport();

	}

	/*
	** scroll
	*/

	scroll (event) {

		this.playVideosInViewport();

	}

	/*
	** video
	*/

	playVideosInViewport() {

		if ( !this.count )
		{
			return false;
		}

		for (let i = 0; i < this.count; ++i)
		{
			const el = this.DOM.videos[i];

			if ( Util.isPartInViewport(el) )
			{
				el.play();
			}
			 else
			{
				el.pause();

			}
		}

	}

	/*
	** load
	*/

	load() {

		// super
		super.load();

		// videos
		this.DOM.videos = document.querySelectorAll('.video-play-on-screen');

		this.count = this.DOM.videos.length;

		if ( !this.count )
		{
			return false;
		}

		// play
		this.playVideosInViewport();

	}

	/*
	** unload
	*/

	unload() {

		// super
		super.unload();

	}

}