import PageEvent from '../common/PageEvent.js';

export default class MyFooter extends PageEvent {

	constructor() {

		super();

		this.DOM = {};

		this.DOM.body = document.body;

	}

	/*
	** init
	*/

	init () {

		// container
		this.DOM.container.style.position = 'fixed';
		this.DOM.container.style.bottom = 0;
		this.DOM.container.style.left = 0;
		this.DOM.container.style.width = '100%';
		this.DOM.container.style.zIndex = 1;

	}

	/*
	** resize
	*/

	resize (event) {

		// Console.log('Page::'+ this.name +'::resize');

		this.DOM.footer.style.height = this.DOM.container.offsetHeight + 'px';

	}

	/*
	** load
	*/

	load() {

		// super
		super.load();

		// footer
		this.DOM.footer = document.querySelector('.footer');

		if (this.DOM.footer === null)
		{
			return false;
		}

		this.DOM.container = this.DOM.footer.querySelector('.footer__container');

		// init
		this.init();

		// resize
		this.resize();

	}

	/*
	** unload
	*/

	unload() {

		// super
		super.unload();

	}

}