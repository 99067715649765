import PubSub from 'pubsub-js';
import Console from '../common/Console.js';
import Swiper from  'swiper';
import { Autoplay, EffectFade, Keyboard, Pagination, Navigation } from 'swiper/modules';

export default class MySwiper {

	constructor (selector) {

		this.selector = selector;

		this.DOM = {};

		this.options =
		{
			// modules
			modules: [ Autoplay, EffectFade, Keyboard, Pagination, Navigation ],

			// effect
			effect: 'fade',
			speed: 800,
			simulateTouch: false,

			// slides
			loop: true,
			slidesPerView: 1,
			centeredSlides: false,
			spaceBetween: 0,
			roundLengths: true,

			// interactivity
			grabCursor: false,
			mousewheel: false,
			preventInteractionOnTransition: false,

			// lazy
			lazy: true,
			lazyPreloadPrevNext: 1,
			lazyPreloaderClass: 'swiper-lazy-loading',

			// keyboard
			keyboard: {
				enabled: true,
			},

			// pagination
			pagination:
			{
				el: this.selector + ' .swiper-pagination',
				type: 'fraction'
			},

			// navigation
			navigation: {
				nextEl: this.selector + ' .swiper-button-next',
				prevEl: this.selector + ' .swiper-button-prev'
			},
		};

	}

	/*
	** set
	*/

	setOption (key, value) {

		this.options[key] = value;

	}

	/*
	** slideTo
	*/

	slideTo (index, speed) {

		if (this.swiper === undefined)
		{
			return false;
		}

		if (speed === undefined)
		{
			speed = this.options.speed;
		}

		this.swiper.slideToLoop(index, speed);

	}

	/*
	** nextSlideOnClick
	*/

	nextSlideOnClick() {

		if (this.count < 2)
		{
			return false;
		}

		this.DOM.slides.forEach( item =>
		{
			const overlay = item.querySelector('.swiper-overlay');

			if (overlay !== null)
			{
				overlay.addEventListener('click', event =>
				{
					event.preventDefault();
					event.stopPropagation();

					this.swiper.slideNext();
				});
			}
		});

	}

	/*
	** load
	*/

	load() {

		Console.log('Swiper::load');

		this.DOM.element = document.querySelector(this.selector);

		if (this.DOM.element === null)
		{
			return false;
		}

		// slides
		this.DOM.slides = this.DOM.element.querySelectorAll('.swiper-slide');

		// count
		this.count = this.DOM.slides.length;

		// no slides
		if ( ! this.count )
		{
			return false;
		}

		// only 1 slide
		if ( this.count == 1 )
		{
			this.options.loop = false;
			this.options.simulateTouch = false;
			this.options.grabCursor = false;
		}

		this.swiper = new Swiper(this.selector, this.options);

		// events
		this.swiper.on('slideChange', () =>
		{
			PubSub.publish('swiper.slideChange', {index: this.swiper.realIndex});
		});

		// next
		this.nextSlideOnClick();

		// publish
		PubSub.publish('swiper.slideChange', {index: 0});
		PubSub.publish('swiper.ready', {});


	}

	/*
	** unload
	*/

	unload() {

		Console.log('Swiper::unload');

		if (this.swiper !== undefined)
		{
			this.swiper.destroy();
			delete this.swiper;
		}

	}

}