import MySwiper from '../class/MySwiper.js';

export default class MySlideshows {

	constructor() {

		this.DOM = {};

	}

	/*
	** load
	*/

	load() {

		this.DOM.items = document.querySelectorAll('.slideshow');

		this.DOM.items.forEach( item =>
		{
			const id = '#' + item.id;

			item.swiper = new MySwiper(id);

			item.swiper.setOption('autoplay',
			{
				delay: 2500,
				disableOnInteraction: false
			});

			item.swiper.load();

		});

	}

	/*
	** unload
	*/

	unload() {

		this.DOM.items.forEach( item =>
		{
			if (item.swiper !== undefined)
			{
				item.swiper.destroy();
				delete item.swiper;
			}
		});

	}

}