import MyPage from '../module/MyPage.js';

export default class Page extends MyPage {

	constructor() {

		super();

	}

	/*
	** init
	*/

	init() {

		// super
		super.init();

	}

	/*
	** unload
	*/

	unload() {

		// super
		super.unload();

	}
}